(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/adobe-target-notification/assets/javascripts/notification-api.js') >= 0) return;  svs.modules.push('/components/components/adobe-target-notification/assets/javascripts/notification-api.js');

'use strict';

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const getConfigValues = () => {
  if (svs.components && svs.components.adobeTargetNotification && svs.components.adobeTargetNotification.data && svs.components.adobeTargetNotification.data.config) {
    return svs.components.adobeTargetNotification.data.config;
  }
  throw new Error('Missing config for adobe target notification api');
};
const getEndpoint = _ref => {
  let {
    serverDomain,
    client,
    sessionId
  } = _ref;
  return "https://".concat(serverDomain, "/rest/v1/delivery?client=").concat(client, "&sessionId=").concat(sessionId);
};
const apiCall = _ref2 => {
  let {
    config,
    data,
    callback = () => {},
    errorCallback = () => {}
  } = _ref2;
  if (config.tntId) {
    if (window.navigator.sendBeacon) {
      const status = window.navigator.sendBeacon(getEndpoint(config), JSON.stringify(data));
      if (status) {
        callback({});
      } else {
        errorCallback({
          status: 400
        }, 'Couldnt add call to sendBeacon queue');
      }
    } else {
      $.ajax({
        type: 'POST',
        contentType: 'application/json',
        cache: false,
        url: getEndpoint(config),
        data: JSON.stringify(data),
        dataType: 'json',
        success: callback,
        error: errorCallback
      });
    }
  }
};
const createNotificationRequestObject = _ref3 => {
  let {
    tntId,
    timestamp,
    propertyToken,
    mboxName,
    tokens,
    type
  } = _ref3;
  const response = {
    id: {
      tntId
    },
    property: {
      token: propertyToken
    },
    context: {
      channel: 'web'
    },
    notifications: [{
      id: "".concat(mboxName, "Notification"),
      timestamp,
      mbox: {
        name: mboxName
      },
      tokens,
      type
    }]
  };



  return response;
};
svs.components.adobeTargetNotification.display = (_ref4, callback) => {
  let {
    mboxName,
    tokens,
    state,
    errorCallback
  } = _ref4;
  const config = getConfigValues();
  const data = createNotificationRequestObject(_objectSpread(_objectSpread({}, config), {}, {
    mboxName,
    tokens,
    state,
    type: 'display'
  }));
  apiCall({
    data,
    config,
    callback,
    errorCallback
  });
};
svs.components.adobeTargetNotification.click = (_ref5, callback) => {
  let {
    mboxName,
    tokens,
    errorCallback
  } = _ref5;
  const config = getConfigValues();
  const data = createNotificationRequestObject(_objectSpread(_objectSpread({}, config), {}, {
    mboxName,
    tokens,
    type: 'click'
  }));
  apiCall({
    data,
    config,
    callback,
    errorCallback
  });
};

 })(window);